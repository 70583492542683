import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoCall, IoLogoWhatsapp } from "react-icons/io5";

function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  const [open, setOpen] = useState(false);
  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full z-10 transition duration-300 lg:py-4 py-1 ${
          scrolled ? "bg-white shadow-b shadow-sm" : "bg-lime-100"
        }`}
      >
        <div className="container 2xl:w-full mx-auto w-11/12">
          <div className="w-full flex items-center justify-between py-3">
            <Link to="/" className="w-full flex items-center">
              <div className="">
                <h3 className="font-play font-extrabold text-black/70 lg:text-2xl text-lg leading-tight">
                  rentmyjimny
                </h3>
              </div>
            </Link>

            <div className="items-center lg:gap-6 gap-4 flex">
              <Link
                to="tel:+447851802772"
                className="rounded-[8px] lg:p-3 p-2 flex items-center justify-center border border-black/30"
              >
                <IoCall className="text-lime-500 lg:text-2xl text-xl" />
              </Link>
              <Link
                to="https://wa.link/r182jl"
                className="rounded-[8px] lg:p-3 p-2 flex items-center justify-center border border-black/30"
              >
                <IoLogoWhatsapp className="text-lime-500 lg:text-2xl text-xl" />
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;
