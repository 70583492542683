import React from 'react'
import Hero from '../components/home/Hero'
import Intro from '../components/home/Intro'
import Cta from '../components/home/Cta'
import Faq from '../components/home/Faq'
import Ready from '../components/home/Ready'
import Models from '../components/home/Models'
import Navbar from '../components/common/Navbar'
import Contact from '../components/home/Contact'
import Footer from '../components/common/Footer'

const Home = () => {
		return (
			<div>
				<Navbar />
				<Hero />
				<Intro />
				<Cta />
				<Ready	/>
				<Faq />
				<Models />
				<Contact />
				<Footer />
				
</div>
		)
}

export default Home