import React from 'react'


const items = [
  {
    id: 1,
    question: "How can I book a Jimny rental?",
    answer:
      "Simply click the WhatsApp Now button to initiate a booking through WhatsApp. Our team will guide you through the process.",
  },
  {
    id: 2,
    question: "Are there any age restrictions?",
    answer:
      "Yes, drivers must be at least 21 years old and hold a valid driver’s license.",
  },
  {
    id: 3,
    question: "Can I rent for long periods?",
    answer:
      "Absolutely! We offer flexible rental options, including daily, weekly, and monthly plans.",
  },
  {
    id: 4,
    question: "Is insurance included?",
    answer:
      "Yes, our rentals include comprehensive insurance for your peace of mind.",
  },
];
const Faq = () => {
		return (
      <section className="h-full w-full bg-blue-100 py-[120px]">
        <div className="lg:w-full w-11/12 container mx-auto">
          <h1 className="lg:text-[44px] text-[32px] text-center font-extrabold text-black/80">
            Got Questions?
          </h1>
          <div className="mt-[40px] w-full lg:grid lg:grid-cols-2 flex flex-col lg:flex-row gap-[60px]">
						{items.map((item, idx) => {
							return (
                <div key={idx} className='w-full'>
                  <h2 className="lg:text-[24px] text-[20px] font-semibold text-black/80">
                    {item?.question}
                  </h2>
                  <p className="text-black/60 font-sans lg:text-base text-sm pt-4 lg:w-[400px] w-full">
                    {item?.answer}
                  </p>
                </div>
              );
												})}
            
          </div>
        </div>
      </section>
    );
}

export default Faq