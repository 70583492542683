import React from "react";
import img from "../../assets/img/ex-1.jpg";
import img2 from "../../assets/img/ex-2.jpg";
import img3 from "../../assets/img/ex-3.jpg";

const items = [
  {
    id: 1,
    img: img,
    title: "LED headlamps with washers",
    desc: "Take pride in the Jimny's heritage, symbolised by iconic round headlamps. Washers are standard for LED headlamps, allowing confident driving in mud, dirt or snow.",
  },
  {
    id: 2,
    img: img2,
    title: "Optimised bumpers",
    desc: "All’s clear for adventure, including angled bumper edges that increase clearance at the wheels for assured climbing over obstacles. Their moulded black material safeguards the body from stone chips and scratches.",
  },
  {
    id: 3,
    img: img3,
    title: "Practical drip rail",
    desc: "Let the Jimny take you on your all-weather adventures. The drip rail can keep your head dry and your spirits bright when entering and exiting by helping to prevent water from dripping off the roof.",
  },
];
const Ready = () => {
  return (
    <section className="bg-white h-full py-[100px]">
      <div className="lg:w-full w-11/12 container mx-auto">
        <div>
          <h1 className="lg:text-[44px] text-[32px] font-extrabold text-black/80">
            Ready to conquer the wild
          </h1>
          <p className="font-normal pt-2 font-sans text-black/60 lg:text-base text-sm">
            Built to tackle the harshest weather and terrain, the Jimny goes
            where other vehicles fear to tread. Take on nature’s challenges.
          </p>
          <p className="font-normal pt-2 font-sans text-black/60 lg:text-base text-sm">
            Unleash your spirit of adventure and get ready to explore.
          </p>
        </div>
        <div className="w-full lg:grid lg:grid-cols-3 flex flex-col lg:flex-row gap-8 mt-8">
          {items.map((item, idx) => {
            return (
              <div>
                <div className="h-[250px] rounded-[40px] overflow-hidden">
                  <img
                    src={item?.img}
                    alt="galeri"
                    className="w-full h-full object-cover"
                  />
                </div>
                <h2 className="pt-8 font-extrabold lg:text-[20px] text-[18px] text-black/80">
                  {item?.title}
                </h2>
                <p className="font-normal pt-2 font-sans lg:text-base text-sm lg:w-[350px] w-full text-black/60">
                  {item?.desc}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Ready;
