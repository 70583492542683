import React from 'react'
import { Link } from 'react-router-dom';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";

const Footer = () => {
		return (
      <section className="w-full py-[80px] bg-[#333]">
        <div className="lg:w-full container w-11/12 mx-auto">
          <div className="w-full lg:grid lg:grid-cols-2 flex flex-col lg:flex-row">
            <div>
              <ul className="">
                <li className="my-[16px]">
                  <Link
                    to="tel:+447851802772"
                    className="lg:text-base text-sm font-sans  text-white font-normal flex items-center gap-5"
                  >
                    <FiPhoneCall className="text-white text-[22px]" />
                    +447851802772
                  </Link>
                </li>
                <li className="my-[16px]">
                  <Link
                    to="#"
                    className="lg:text-base text-sm  font-sans  text-white font-normal flex items-center gap-5"
                  >
                    <HiOutlineLocationMarker className="text-white text-[22px]" />{" "}
                    South Africa
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex items-center  w-full">
              <h3 className="md:text-base text-[14px] text-white opacity-70 font-sans w-full">
                Copyright © Rent My Jimny
              </h3>
              <ul className="items-center flex w-full gap-5">
                <li
                  className={` py-3 cursor-pointer font-normal text-white w-full`}
                >
                  <Link
                    to="#"
                    className="md:text-base text-[14px] font-sans font-normal"
                  >
                    Approved
                  </Link>
                </li>
                <li
                  className={` py-3 cursor-pointer font-normal text-white w-full`}
                >
                  <Link
                    to="#"
                    className="md:text-base text-[14px] font-sans font-normal"
                  >
                    Legal
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Footer