import React from "react";
import img1 from "../../assets/img/jimny-1.webp";
import img2 from "../../assets/img/jimny-2.webp";
import img3 from "../../assets/img/jimny-3.webp";
import img4 from "../../assets/img/jimny-4.webp";
import { FiArrowDown } from "react-icons/fi";
import { BsPlayFill } from "react-icons/bs";

const items = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
];

const Hero = () => {
  return (
    <section className="w-full lg:h-screen h-full py-[100px] bg-lime-100">
      <div className="lg:w-full w-11/12 container mx-auto h-full flex items-center ">
        <div className="lg:w-full w-11/12 flex flex-col lg:flex-row items-center gap-[50px]">
          <div className="flex flex-col h-full gap-9 lg:mt-0 mt-[50px]">
            <h1 className="lg:text-[54px] text-[42px] font-extrabold text-black/80 ">
              Unleash your off-road spirit with our Suzuki Jimny.
            </h1>
            <div className="w-[70px] h-[70px] bg-transparent rounded-full transform animate-bounce cursor-pointer flex items-center justify-center border-2 border-black/80">
              <FiArrowDown className="text-black/80 text-[30px]" />
            </div>
          </div>
          <div>
            <div className="w-full grid grid-cols-2 h-full gap-2 relative">
              {items.map((item, idx) => {
                return (
                  <div className="w-full h-full" key={idx}>
                    <img
                      src={item.img}
                      alt="jimny"
                      className="w-full h-full object-fit"
                    />
                  </div>
                );
              })}
              <div class="row-span-2 col-span-2 flex justify-center items-center absolute left-[43%] top-[40%]">
                <button class="bg-[#333] text-white px-4 py-2  h-[100px] w-[100px] rounded-full flex items-center justify-center group hover:bg-lime-500">
                  <BsPlayFill className="text-lime-500 text-[45px] group-hover:text-[#333]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
