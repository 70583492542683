import React from "react";
import img from "../../assets/img/jimny-d.webp";
import img2 from "../../assets/img/galeri-2.webp";

const items = [
  {
    id: 1,
    img: img,
    title: "Superior Handling",
    desc: "Conquer tough terrains with Suzuki Jimny’s exceptional off-road capabilities.",
  },
  {
    id: 2,
    img: img2,
    title: "Rugged Design",
    desc: "Built to withstand the most challenging off-road adventures.",
  },
];

const Intro = () => {
  return (
    <section className="w-full  h-full py-[100px] bg-white">
      <div className="lg:w-full w-11/12 container mx-auto h-full">
        <div className="w-full lg:grid lg:grid-cols-2 gap-8 flex flex-col lg:flex-row">
          {items.map((item, idx) => {
            return (
              <div>
                <div className="rounded-[40px] shadow-sm lg:h-[490px] h-[320px]">
                  <img
                    src={item?.img}
                    alt="galeri"
                    className="w-full h-full object-cover rounded-[40px]"
                  />
                </div>
                <h2 className="lg:pt-8 pt-6 font-extrabold lg:text-[30px] text-[22px]">
                  {item?.title}
                </h2>
                <p className="font-normal pt-2 font-sans w-[350px] lg:text-lg text-sm text-black/60">
                  {item?.desc}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Intro;
