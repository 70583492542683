import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <section className="py-[100px] w-full bg-[#333]">
      <div className="container lg:w-full w-11/12 mx-auto">
        <div className="flex flex-col items-center justify-center">
          <h1 className="lg:text-[54px] text-[38px] text-white text-center">
            Ready for adventure?
          </h1>
          <p className="lg:text-xl text-base lg:w-[500px] w-full text-white/60 text-center font-sans pt-3">
            Embark on an epic off-road journey with Suzuki Jimny. Don’t miss out
            on the fun – book your rental today!
          </p>
          <Link
            to="https://wa.link/r182jl"
            className="mt-8 rounded-[8px] bg-lime-500 p-4 font-sans text-black/80 font-semibold"
          >
            Whatsapp Now!
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Cta;
