import React from "react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import {
  RiWhatsappLine,
  RiInstagramLine,
  RiTwitterLine,
  RiLinkedinLine,
} from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const items = [
  {
    id: 1,
    title: "Email",
    icon: MdOutlineAlternateEmail,
    url: "mailto:#",
  },
  {
    id: 2,
    title: "Whatsapp",
    icon: RiWhatsappLine,
    url: "https://wa.link/r182jl",
  },
  {
    id: 3,
    title: "Facebook",
    icon: FaFacebookF,
    url: "#",
  },
  {
    id: 4,
    title: "Instagram",
    icon: RiInstagramLine,
    url: "#",
  },
];



function Contact() {
  return (
    <section className="parallax bg-lime-100 h-full bg-center">
      <div className="w-full mx-auto lg:pl-[96px] pl-0 flex flex-col lg:flex-row h-full overflow-hidden">
        <div className="lg:w-[50%] w-full lg:pt-[150px] pt-[129px] lg:pb-0 pb-[129px] px-[16px] lg:px-0">
          <h3 className=" leading-[120%] font-medium lg:text-[48px] text-[36px]">
            <span className="font-sansbold text-black/80 font-semibold">
              Get in touch
            </span>
            <br />
            <span className="font-sans font-medium text-black/60">
              We respond fast
            </span>
          </h3>
          <p className="font-sansregular font-normal text-black lg:text-[24px] text-[18px] lg:mt-[48px] mt-[25px]">
            Our support team is always available
          </p>
        </div>
        <div className="lg:w-[50%] w-full bg-blue-100 lg:px-[102px] px-[16px] lg:py-[91px] py-8 grid grid-cols-2 lg:gap-[28px] gap-[24px] lg:h-full h-[70vh] place-content-center mx-auto my-auto" >
          {items.map((item, index) => {
            const Icon = item?.icon;
            return (
              <Link
                to={item.url}
                key={index}
                className="w-full rounded-[16px] border border-lime-500 lg:px-[48px] px-[30px] cursor-pointer flex items-start flex-col h-full max-h-[189px] lg:hover:shadow-md lg:hover:transition lg:hover:delay-50 lg:hover:ease-in"
              >
                <div className="py-[48px]">
                  <div className="icon-container">
                    <div className="square bg-[#333] w-[70px]  flex items-center justify-center lg:h-[70px] h-[60px] absolute rounded-[8px]">
                      <Icon className="text-white lg:text-[40px] text-[30px]" />
                    </div>
                    <div className="rotated-square bg-[#333]/30 rounded-[8px]"></div>
                  </div>
                  <h3 className="text-black/80 font-normal font-sansregular lg:text-[20px] text-[18px] pt-[20px]">
                    {item.title}
                  </h3>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Contact;
