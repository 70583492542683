import React from "react";
import img from "../../assets/img/fildisi.webp";
import img2 from "../../assets/img/gumus-gri.webp";
import img3 from "../../assets/img/canli-mavi.webp";
import img4 from "../../assets/img/inci-beyazi.webp";
import img5 from "../../assets/img/mavimsi-siyah.webp";
import img6 from "../../assets/img/kinetik-sari.webp";
import img7 from "../../assets/img/orman-yesili.webp";
import img8 from "../../assets/img/orta-gri.webp";

const items = [
  {
    id: 1,
    img: img,
    title: "Chiffon Ivory Metallic",
  },
  {
    id: 2,
    img: img2,
    title: "Silky	Silver Metallic",
  },
  {
    id: 3,
    img: img3,
    title: "Brisk	Blue Metallic",
  },
  {
    id: 4,
    img: img4,
    title: "Superior White",
  },
  {
    id: 5,
    img: img5,
    title: "Bluish Black Pearl",
  },
  {
    id: 6,
    img: img6,
    title: "Kinetic Yellow",
  },
  {
    id: 7,
    img: img7,
    title: "Jungle Green",
  },
  {
    id: 8,
    img: img8,
    title: "Medium Gray",
  },
];
const Models = () => {
  return (
    <section className="bg-white h-full py-[100px]">
      <div className="lg:w-full w-11/12 container mx-auto">
        <div>
          <h1 className="lg:text-[44px] text-[32px] font-extrabold text-black/80">
            Our Suzuki Jimny Colors
          </h1>
          <p className="font-normal pt-2 font-sans text-black/60 lg:text-base text-sm">
            Choose from a range of color variations that will suit your style.
          </p>
        </div>
        <div className="w-full grid lg:grid-cols-4 grid-cols-2 gap-10 mt-11">
          {items.map((item, idx) => {
            return (
              <div>
                <div className="h-[150px] rounded-[40px] overflow-hidden">
                  <img
                    src={item?.img}
                    alt="galeri"
                    className="w-full h-full object-cover"
                  />
                </div>
                <h2 className="pt-8 font-extrabold text-base text-center text-black/80">
                  {item?.title}
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Models;
